<template>
  <b-container fluid>
      <b-row v-if="!loader">
        <b-col lg="12" sm="12">
          <iq-card>
            <template>
                <b-row>
                    <b-col lg="12" sm="12" class="text-center mb-4">
                      <h5 class='complain-title'>{{ $t('germConfig.germplasm_organization') }}</h5>
                    </b-col>
                </b-row>
                <b-row>
                  <b-col lg="12" sm="12">
                    <b-row>
                      <b-col lg="3" sm="12">
                        <p class="text-dark">{{$t('germConfig.organization_name_en')}} : </p>
                      </b-col>
                      <b-col lg="3" sm="12">
                         <p class="text-dark capitalize">{{ data.organization_name  }}</p>
                      </b-col>
                       <b-col lg="3" sm="12">
                        <p class="text-dark">{{$t('germConfig.organization_name_bn')}} : </p>
                      </b-col>
                      <b-col lg="3" sm="12">
                         <p class="text-dark capitalize">{{ data.organization_name_bn  }}</p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="3" sm="12">
                        <p class="text-dark">{{$t('globalTrans.code')}} : </p>
                      </b-col>
                      <b-col lg="3" sm="12">
                         <p class="text-dark capitalize">{{ data.organization_code  }}</p>
                      </b-col>
                       <b-col lg="3" sm="12">
                        <p class="text-dark">{{$t('globalTrans.phone_no')}} : </p>
                      </b-col>
                      <b-col lg="3" sm="12">
                         <p class="text-dark capitalize">{{ data.phone_no  }}</p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="3" sm="12">
                        <p class="text-dark">{{$t('globalTrans.email')}} : </p>
                      </b-col>
                      <b-col lg="3" sm="12">
                         <p class="text-dark capitalize">{{ data.email  }}</p>
                      </b-col>
                       <b-col lg="3" sm="12">
                        <p class="text-dark">{{$t('germConfig.tin_no')}} : </p>
                      </b-col>
                      <b-col lg="3" sm="12">
                         <p class="text-dark capitalize">{{ data.tin_no  }}</p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="3" sm="12">
                        <p class="text-dark">{{$t('globalTrans.address')}} : </p>
                      </b-col>
                      <b-col lg="3" sm="12">
                         <p class="text-dark capitalize">{{ data.address  }}</p>
                      </b-col>
                       <b-col lg="3" sm="12">
                        <p class="text-dark">{{$t('globalTrans.address_bn')}} : </p>
                      </b-col>
                      <b-col lg="3" sm="12">
                         <p class="text-dark capitalize">{{ data.address_bn  }}</p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="3" sm="12">
                        <p class="text-dark">{{$t('globalTrans.remarks')}} : </p>
                      </b-col>
                      <b-col lg="3" sm="12">
                         <p class="text-dark capitalize">{{ data.remarks  }}</p>
                      </b-col>
                       <b-col lg="3" sm="12">
                        <p class="text-dark">{{$t('globalTrans.remarks_bn')}} : </p>
                      </b-col>
                      <b-col lg="3" sm="12">
                         <p class="text-dark capitalize">{{ data.remarks_bn  }}</p>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
export default {
  props: ['id'],
  created () {
    if (this.id) {
      const tmp = this.getOrganizationData()
      this.data = tmp
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      saveBtnName: this.$t('globalTrans.view'),
      data: {
        id: '',
        allocation_type: 1,
        org_id: 0,
        fiscal_year_id: 0,
        fiscal_year_bn: '',
        fiscal_year: '',
        production_season_id: 0,
        division_id: 0,
        district_id: 0,
        seed_class_id: 0,
        crop_type_id: 0,
        crop_name_id: 0,
        price_type_id: 0,
        carryovers_id: 0,
        from_spc_office_id: 0,
        to_sales_office_id: 0,
        measurement_unit_id: 0,
        allocation_date: null,
        details: []
      },
      cropNameList: [],
      loader: false,
      search: {
        crop_name_id: 0
      }
    }
  },
  methods: {
    getOrganizationData (id) {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    }
  }
}
</script>
