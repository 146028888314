// cropType api
export const cropList = '/germplasm/config/crop-types/list'
export const cropTypeStore = '/germplasm/config/crop-types/store'
export const cropTypeUpdate = '/germplasm/config/crop-types/update'
export const cropTypeToggleStatus = '/germplasm/config/crop-types/toggle-status'
export const cropTypeDestroy = '/germplasm/config/crop-types/destroy'

// cropName api
export const cropNameList = '/germplasm/config/crop-names/list'
export const cropNameStore = '/germplasm/config/crop-names/store'
export const cropNameUpdate = '/germplasm/config/crop-names/update'
export const cropNameToggleStatus = '/germplasm/config/crop-names/toggle-status'
export const cropNameDestroy = '/germplasm/config/crop-names/destroy'

// conservation type api
export const conservationTypeList = '/germplasm/config/conservation-type/list'
export const conservationTypeStore = '/germplasm/config/conservation-type/store'
export const conservationTypeUpdate = '/germplasm/config/conservation-type/update'
export const conservationTypeToggleStatus = '/germplasm/config/conservation-type/toggle-status'
export const conservationTypeDestroy = '/germplasm/config/conservation-type/destroy'

// collectionSource api
export const collectionSourceList = '/germplasm/config/collection-sources/list'
export const collectionSourceStore = '/germplasm/config/collection-sources/store'
export const collectionSourceUpdate = '/germplasm/config/collection-sources/update'
export const collectionSourceToggleStatus = '/germplasm/config/collection-sources/toggle-status'
export const collectionSourceDestroy = '/germplasm/config/collection-sources/destroy'

// characterizationType api
export const characterizationTypeList = '/germplasm/config/characterization-types/list'
export const characterizationTypeStore = '/germplasm/config/characterization-types/store'
export const characterizationTypeUpdate = '/germplasm/config/characterization-types/update'
export const characterizationTypeToggleStatus = '/germplasm/config/characterization-types/toggle-status'
export const characterizationTypeDestroy = '/germplasm/config/characterization-types/destroy'

// unit api
export const unitList = '/germplasm/config/gm-unit/list'
export const unitStore = '/germplasm/config/gm-unit/store'
export const unitUpdate = '/germplasm/config/gm-unit/update'
export const unitToggleStatus = '/germplasm/config/gm-unit/toggle-status'
export const unitDestroy = '/germplasm/config/gm-unit/destroy'

// organization api
export const organizationList = '/germplasm/config/organization/list'
export const organizationStore = '/germplasm/config/organization/store'
export const organizationUpdate = '/germplasm/config/organization/update'
export const organizationToggleStatus = '/germplasm/config/organization/toggle-status'
export const organizationDestroy = '/germplasm/config/organization/destroy'

// Germplasm Receiver Info Api Routes.....
export const receiverInfoList = '/germplasm/config/receiver-info/list'
export const receiverInfoStore = '/germplasm/config/receiver-info/store'
export const receiverInfoUpdate = '/germplasm/config/receiver-info/update'
export const receiverInfoToggleStatus = '/germplasm/config/receiver-info/toggle-status'
export const receiverInfoDestroy = '/germplasm/config/receiver-info/destroy'

// Report Headers Routes
export const reportHeadingList = '/germplasm/config/report-heading/list'
export const reportHeadingStore = '/germplasm/config/report-heading/store'
export const reportHeadingUpdate = '/germplasm/config/report-heading/update'
export const reportToggleStatus = '/germplasm/config/report-heading/toggle-status'
export const reportHeaderDestroy = '/germplasm/config/report-heading/destroy'

// Report Headers Routes
export const descriptorLabelList = '/germplasm/config/descriptor-profile/list'
export const descriptorLabelStore = '/germplasm/config/descriptor-profile/store'
export const descriptorLabelUpdate = '/germplasm/config/descriptor-profile/update'
export const descriptorLabelToggleStatus = '/germplasm/config/descriptor-profile/toggle-status'
export const descriptorLabelDestroy = '/germplasm/config/descriptor-profile/destroy'

// descriptor Heading api Routes
export const descriptorHeadingList = '/germplasm/config/descriptor-heading/list'
export const descriptorHeadingStore = '/germplasm/config/descriptor-heading/store'
export const descriptorHeadingUpdate = '/germplasm/config/descriptor-heading/update'
export const descriptorHeadingToggleStatus = '/germplasm/config/descriptor-heading/toggle-status'
export const descriptorHeadingDestroy = '/germplasm/config/descriptor-heading/destroy'

// master Code Guide Routes
export const codeGuideList = '/germplasm/config/code-guide/list'
export const codeGuideStore = '/germplasm/config/code-guide/store'
export const codeGuideUpdate = '/germplasm/config/code-guide/update'
export const codeGuideToggleStatus = '/germplasm/config/code-guide/toggle-status'
export const codeGuideDestroy = '/germplasm/config/code-guide/destroy'
